<template>
  <div>
    <v-container>
      <!-- New interface -->

      <v-row>
        <v-col cols="12">
          <v-img
            class="white--text align-end"
            height="356px"
            src="/img/banner.png"
          >
          </v-img>
        </v-col>
      </v-row>

      <v-row class="mt-1">
        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >About Us</v-card-title
              >
            </v-system-bar>

            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://dlight.com"
              >
                Global
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://www.dlight.com/social-impact/"
              >
                Social Impact
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                href="#"
              >
                Nigeria
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Our Products</v-card-title
              >
            </v-system-bar>

            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://www.dlight.com/products/"
              >
                Solar Lanterns
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://www.dlight.com/products/"
              >
                Solar Home Systems
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://www.dlight.com/products/"
              >
                Inverter-Battery Systems
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >How to Pay</v-card-title
              >
            </v-system-bar>


            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                to="Paygo"
              >
                Pay with Monnify
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                to="login"
              >
                Pay with Direct Debit
              </v-btn>
            </v-card-actions>

            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                to="PaymentChannels"
              >
                <!-- to=paygo -->
                Others
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Shop</v-card-title
              >
            </v-system-bar>

            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
              >
                eShop
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://www.jumia.com.ng/dlight-solar-nigeria/"
              >
                Jumia
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://www.konga.com/merchant/dlight-solar-nigeria"
              >
                Konga
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Contact Us</v-card-title
              >
            </v-system-bar>

            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
              >
                Call Center
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
              >
                Email
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
              >
                Regional Offices & Service Centers
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Testimonials</v-card-title
              >
            </v-system-bar>

            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
                target="_blank"
                href="https://www.youtube.com/c/dlightnigeria"
              >
                YouTube
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
              >
                Voice of Customers
              </v-btn>
            </v-card-actions>
            <v-card-actions class="justify-center py-1">
              <v-btn
                class="font-weight-bold text-subtitle-1 btn"
                color="deep-orange lighten-1"
                text
              >
                Social Impacts
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- New interface ending -->

      <!-- <v-row class="mb-1 mt-5">
         <v-col cols="12" xs="12" md="4" lg="4" sm="12">
          <div>
            <v-row>
              <v-col cols="12" xs="12" md="12" lg="12" sm="12">
                <v-card elevation="20" class="">
                  <v-img
                    class="white--text align-end"
                    height="250px"
                    src="/img/Paymentpanel.jpg"
                  >
                    <v-card-title>Make payment</v-card-title>
                  </v-img>


                  <v-col cols="12" xs="12" md="12" lg="12" sm="12">
                    <v-card class="mx-auto" dark>
                      <v-card-title class="white--text"
                        >Non-recurrent debit payment</v-card-title
                      >
                      <v-card-text>
                        Use this option to make non-recurring payment for your
                        dlight product.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="deep-orange" text to="Paygo">
                          Make Payment
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>

                  <v-col cols="12" xs="12" md="12" lg="12" sm="12">
                    <v-card class="mx-auto" dark>
                      <v-card-title class="white--text"
                        >Auto-debit payment</v-card-title
                      >
                      <v-card-text>
                        Make payment with this option using your credit or debit
                        card to activate auto-debit and enjoy non-stop power
                        with dlight iMax 10 and other product.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="deep-orange" text to="login">
                          Login/Register
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>

                  <v-col cols="12" xs="12" md="12" lg="12" sm="12">
                    <v-card class="mx-auto" dark>
                      <v-card-title class="white--text"
                        >Bank mandate auto-debit</v-card-title
                      >
                      <v-card-text>
                        Use this option to generate bank mandate and activate
                        bank account auto-debit to enjoy non-stop power.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="deep-orange" text to="#">
                          Coming soon...
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col> -->

      <!-- <v-col cols="12" xs="12" md="8" lg="8" sm="12">
          <AboutForm />
        </v-col>
      </v-row>-->

      <!-- <v-row class="mb-1">
        <v-col>
          <v-card class="mx-auto" elevation="20">
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4" sm="4">
                <v-img
                  class="white--text mx-2"
                  height="200px"
                  src="/img/A2.jpg"
                >
                </v-img>
              </v-col>
              <v-col cols="12" xs="12" md="8" lg="8" sm="8">
                <v-card-subtitle class="text-h5"
                  >Solar Lanterns</v-card-subtitle
                >
                <v-card-text>
                  In 2014, d.light Nigeria was established to accelerate the
                  distribution and adoption of affordable and reliable solar
                  lanterns that will improve the quality of life that comes with
                  access to safe and durable solar lanterns compared to kerosene
                  lanterns, candle lights and unreliable rechargeable lamps.
                  Over 500,000 solar lanterns have been sold to market women,
                  traders, artisans, schools, students, hospitals, rural
                  communities and cooperatives directly through over 400 retail
                  partners and indirectly through distributors and NGOs.
                </v-card-text>
                <v-card-actions class="mt-1">
                  <v-btn text color="deep-orange darken-1"> Learn more </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col>
          <v-card class="mx-auto" elevation="20">
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4" sm="4">
                <v-img
                  class="white--text mx-2"
                  height="200px"
                  src="/img/X1000.jpg"
                >
                </v-img>
              </v-col>
              <v-col cols="12" xs="12" md="8" lg="8" sm="8">
                <v-card-subtitle class="text-h5"
                  >Solar Home Systems (Solar TVs, Solar Fans, Light Bulbs and
                  Solar Boxes)</v-card-subtitle
                >
                <v-card-text>
                  In April 2019, d.light Nigeria introduced Solar Home Systems
                  with Pay-As-You-Go (PayGo) financing solutions to drive
                  affordability and provide lighting, cooling and entertainment
                  in homes and businesses. Our range of Solar Home Systems
                  including the X1000, D100, Solar TVs and Solar Fans have
                  transformed the lives of over 1,100,000 Nigerians with over
                  223,000 appliances sold.
                </v-card-text>
                <v-card-actions class="mt-1">
                  <v-btn text color="deep-orange darken-1"> Learn more </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col>
          <v-card class="mx-auto" elevation="20">
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4" sm="4">
                <v-img
                  class="white--text mx-2"
                  height="200px"
                  src="/img/IMAX.jpg"
                >
                </v-img>
              </v-col>
              <v-col cols="12" xs="12" md="8" lg="8" sm="8">
                <v-card-subtitle class="text-h5"
                  >Solar Power Systems (Inverters & Batteries)</v-card-subtitle
                >
                <v-card-text>
                  We introduced our Solar Power Systems (Inverter & Battery
                  Solutions) in 2022 to enable our customers power their
                  existing home appliances and meet their lifestyle aspirations
                  anytime, anywhere. Our Inverters & Batteries give our
                  customers the freedom to do more and live a healtheir and
                  peaceful life that is free from generator noise, fumes
                  (smoke), increasing cost of maintenance and petrol
                </v-card-text>
                <v-card-actions class="mt-1">
                  <v-btn text color="deep-orange darken-1"> Learn more </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col>
          <v-card class="mx-auto" elevation="20">
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4" sm="4">
                <v-img
                  class="white--text mx-2"
                  height="200px"
                  src="/img/A2.jpg"
                >
                </v-img>
              </v-col>
              <v-col cols="12" xs="12" md="8" lg="8" sm="8">
                <v-card-subtitle class="text-h5"
                  >Mobile Phones (Samsung, Tecno, Itel, Nokia)</v-card-subtitle
                >
                <v-card-text>
                  In 2020 we introduced d.light device financing (PayGo)
                  solution for mobile phones. With d.light PayGo, many potential
                  customers who do not have the cash at one time to afford the
                  upfront cost of any product of their choice can now pay in
                  installments over a period of time. d.light is able to break
                  this affordability barrier and offer ultimate flexibility to
                  our customers. Now our customers can buy a high quality
                  smartphone to do more of what they love with d.light PayGo
                  technology.
                </v-card-text>
                <v-card-actions class="mt-1">
                  <v-btn text color="deep-orange darken-1"> Learn more </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>



<style scoped>
@import "../assets/mystyles.css";

.btn {
  text-transform: unset !important;
}
</style>

<script>
// Components
// import TranxForm from "../components/forms/tranx_form.vue";
//import AboutForm from "../components/forms/about_form.vue";
//import AboutForm2 from "../components/forms/about2_form.vue";

export default {
  name: "HomeView",

  components: {
    // TranxForm,
    //AboutForm,
    //AboutForm2,
  },
  data() {
    return {
      colors: [
        "green lighten-4",
        "secondary lighten-4",
        "yellow lighten-4",
        "red lighten-4",
        "orange lighten-4",
      ],
      cycle: false,
      slides: [
        {
          src: require("../assets/img/headerimage.jpg"),
        },
      ],
    };
  },
};
</script>
